.download-button {
    padding: 7.5px;
    background-color: #0ac4c4;
    outline: none;
    border-radius: 8px;
    border: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.error-message {
    color: #cd1818;
}

p,
h3 {
    margin: 0;
    height: fit-content;
}

.format-p {
    background-color: #d1d1d1;
    border: 1px solid #bababa;
    color: black;
    margin: 0;
    height: fit-content;
    font-size: 14px;
    padding: 5px;
    border-radius: 8px;
    text-transform: uppercase;
}

.item {
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #cfcfcf;
}

.font-display-container {
    height: calc(300px - 40px);
    display: grid;
}

.item-info-container {
    align-items: center;
    background-color: #f1f1f1;
    border-top: 1px solid #cfcfcf;
    height: 40px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    display: flex;
    flex-direction: row;
    width: calc(400px - 20px);
    justify-content: space-between;
    padding: 5px 10px;
}

@keyframes backAndForth {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(0);
    }

    45% {
        transform: translateX(calc(-100% + 180px));
    }

    55% {
        transform: translateX(calc(-100% + 180px));
    }

    90% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
}

.animated {
    animation: backAndForth 2s linear infinite;
}

h3 {
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    line-height: 2;
}

.container {
    overflow: hidden;
    width: 180px;
}

.download-container {
    position: relative; /* To position the dropdown */
    display: inline-block; /* To contain the dropdown */
}

.download-links {
    position: absolute;
    top: 100%; /* Position below the button */
    left: -200px;
    background-color: #0ac4c4;
    border: 1px solid #00a19e;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 10px;
    z-index: 1; /* Ensure it's above other content */
    min-width: 300px; /* Adjust as needed */
}

.download-links a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #ffffff;
    border-radius: 6px;
}

.download-links a:hover {
    background-color: #08a8ab;
}