@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  font-family: 'Open Sans';
}

p {
  color: darkgray;
}

.App {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.items {
  justify-content: center;
  padding: 50px;
  padding-top: 0px;
  width: calc(100% - 100px);
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 30px;
}

button {
  font-size: 24px;
  background-color: #0ac4c4;
  border:1.5px solid #0fabab;
  padding: 20px 40px;
  border-radius: 8px;
  outline: none;
  color: white;
  cursor: pointer;
}
.small-button {
  font-size: 16px;
  color: black;
  background-color: #dbdbdb;
  border: 0;
  padding: 10px 20px;
}

.input-wrapper {
  cursor: text;
  padding: 20px;
  gap: 10px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid #cfcfcf;
  background-color:#f1f1f1;
  border-radius: 14px;
  z-index: 999;
  margin-bottom: 150px;
  width: 50%;
  max-width: 600px;
  min-width: 300px;
}

input {
  padding: 0px;
  border: 0px;
  outline: 0px;
  width: 100%;
  font-size: x-large;
  background-color: transparent;
}

header {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  align-self: center;
  gap: 20px;
  width: 50%;
  max-width: 600px;
  min-width: 300px;
  padding: 10px 20px;
}

.logo {
  height: 100px;
}